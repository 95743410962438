.main
{ 
    font-family: 'Poppins';
}


.main .registration-form 
{
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 236px;
    position: relative;
    overflow: hidden;  
    padding: 2% 15%; 
     
}

.main .heading
{
    color: white;
}
.main .form-section{
    font-size: 14px;
}

.main .registration-form .text-container .home-link
{
    color: white;
    font-weight: bold;
    text-decoration: none;
    border: none;
}
.main .registration-form .text-container
{
    color: white;
    
}
.main .complete-form{
    position: relative;
    top: -50px;
    width: 100%;
}
.main .form-section .overlay-form
{
    background-color: white;
    padding: 25px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    padding: 2% 15%; 
   font-weight: bold;
}
.main .form-section .form-control
{
    border-color: #707070 ;       
}

.main .main-form .custom-button .btn
{
    padding: 10px 40px; 
    cursor: pointer; 
    background-color: #0054B3;
    color: white;
    border: 3px solid #bacadb;
    border-style: double;
}
.main .main-form .custom-button .btn:hover
{
    color: black; 
   border: 2px solid #0054B3;
   background-color: white;
}

.main .main-form .custom-button
{
    text-align: center;
    padding: 20px;
}
.main .border-one
{
    background-color: #FF8E00;
    bottom: 0px;
    height: 2px;
    width: 50%;
    position: relative;   
    left: 50%;
    
}


/* new */

/* .main .book-main {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 5px 5px;
    padding: 2% 15%;
    position: relative;
    left: 0;
    right: 0;
    bottom: 10%;
    z-index: 9;
} */

.main  .next-btn {
    width: 121px;
    height: 36px;
    border: 1px solid #0054B3;
    color: #000;
    font-size: 15px;
    font-weight: bold;
}

.main .Book-Form .form-label {
    margin-bottom: .5rem;
    font-size: 14px;
} 


.main .Book-Form  .form-control {
  font-size: 14px;
  background-color: #fff;
  border-color: #707070;
}

.main input#exampleaddress1 {
    height: 60px;
}

.star-img {
   color: red;
}

@media screen and (min-width: 280px) and (max-width: 320px) {

    .main .complete-form {
        top: -57px;
        width: 100%;
        right: 0%;
    }

    .main .heading {
        position: absolute;
        top: 32%;
    }

    .main .heading h3 {
     font-size: 20px;
    }

  }
  @media screen and (min-width: 321px) and (max-width: 485px) {

    
    .main .complete-form {
        top: -68px;
        width: 100%;
        right: 0%;
    }

   
  }
  @media screen and (min-width: 486px) and (max-width: 767px) {
       
    .main .registration-form 
    {
        padding-left: 80px;
    }
       
    
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {

    .main .registration-form 
    {
        padding-left: 80px;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    .main .registration-form 
    {
        padding-left: 80px;
    }
    
}
  @media screen and (min-width: 1200px) and (max-width: 1440px) {

    .main .registration-form 
    {
        padding-left: 150px;
    }
  }