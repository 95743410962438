/* plans and package banner css start */
.main .Book-Banner {
    background-image: url("/public/assets/Images/BookNewConnection/Banner/book-banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 236px;
    position: relative;
  }
  
  .main .Book-Banner .banner-text {
    padding-top: 3%;
  }
  
  .main .Book-Banner .banner-text .text-holder h1 {
    font-family: poppins;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 3%;
    margin-top: 8%;
  }
  
  .main .Book-Banner .banner-text .city-form .form-select {
    width: 50%;
    border-radius: 0px;
    color: #0a0a0a8c;
    font-size: 14px;
    font-weight: normal;
    font-family: poppins;
  }
  
  .main .Book-Banner .banner-text .city-form label {
    font-size: 14px;
    font-weight: normal;
    font-family: poppins;
    color: #ffffff;
    margin-bottom: 2%;
  }
  /* plans and package banner css end */
  