
.main .registration-form 
{
    background-image: url("/public/assets/Images/Subscribe_Corner//banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 236px;
    position: relative;
    overflow: hidden;
    
}




