
/* start .how-fast-section style  */
.how-fast-section  {
    margin: 5% 0% 0% 0%;
    position: relative;
    width:100%;
}
.how-fast-section .video-sec .video{
    width: 100%;
}
.how-fast-section .blackoverlay{
    background: #000b2dc7;
    position: absolute;
    top: 0;
    width: 100%;
    height: 99%;
}
.how-fast-section .content-overflow-1{
    position: absolute;
    top: 10%;
}
.how-fast-section .content-overflow-1 .title-set{
    color: #fff;    
    font-weight: 600;
    font-size: 45px;
}
.how-fast-section .content-overflow-1 .content{
    font-weight: normal;
    font-size: 17px;
    color: #fff;
    margin-bottom: 0px;
    text-align: justify;
}
.how-fast-section .content-overflow-1 .sec{
   
    width: 70%;
}
.how-fast-section .content-overflow-1 .sec-2 .icon-div .icon{
    width: 75px;
}
.how-fast-section .content-overflow-1 .sec-2 .get .get-p{
font-size: 17px;
color: #fff;
margin-bottom: 0px;
}
.how-fast-section .content-overflow-1 .sec-2 .get .spped{
    font-weight: bold;
    font-size: 55px;
    color: #fff;
    margin-bottom: 0px;
    margin-top: -15px;
}
.how-fast-section .content-overflow-1 .sec-2 .get .gbps{
    font-size: 17px;
    color: #fff;
    margin-bottom: 0px;
    margin-top: 26px;
}
.how-fast-section .content-overflow-1 .ultra{
    font-size: 17px;
    color: #fff;
    margin: -16px 0px 0px 8px;
}
.how-fast-section .content-overflow-1 .sec-3 .rout-img{
    width: 70%;
}




@media (min-width:0px) and (max-width:575px){
    .how-fast-section .video-sec .video {
        display: none;
    }
    .how-fast-section {
        margin: 5% 0% 0% 0%;
        position: relative;
        width: 100%;
        background: #000;
        height: 100vh;
    }
    .how-fast-section .content-overflow-1 .sec {
        width: 100%;
        text-align: center;
    }
    .how-fast-section .content-overflow-1 .title-set {
        font-size:25px;
    }
    .how-fast-section .content-overflow-1 .sec-2{
        margin-top:50px;
    }
    .how-fast-section .content-overflow-1 .sec-3{
        margin-top:50px;
    }
    .how-fast-section .content-overflow-1 .sec-2 .icon-div .icon {
        width: 50px;
    }
    .how-fast-section .content-overflow-1 .sec-2 .get .get-p {
        font-size: 10px;
    }
    .how-fast-section .content-overflow-1 .sec-2 .get .spped {
        font-size: 37px;
    }
    .how-fast-section .content-overflow-1 .sec-2 .get .gbps {
        font-size: 10px;
        margin-top: 16px;
    }
    .how-fast-section .content-overflow-1 .content {
        font-size: 15px;
    }
    .how-fast-section .blackoverlay {
        display: none;
    }
    .how-fast-section {
        margin: 5% 0% 0% 0%;
        position: relative;
        width: 100%;
        background: #000;
        padding: 50px 0px;
        height: 100%;
    }
    .how-fast-section .content-overflow-1 {
        position: unset;
}
}


@media (min-width:576px) and (max-width:768px){
    .how-fast-section .video-sec .video {
        display: none;
    }
    .how-fast-section {
        margin: 5% 0% 0% 0%;
        position: relative;
        width: 100%;
        background: #000;
        padding: 50px 0px;
        height: 100%;
    }
    .how-fast-section .content-overflow-1 .sec {
        width: 90%;
    }
    .how-fast-section .content-overflow-1 .title-set {
        font-size: 35px;
    }
    .how-fast-section .content-overflow-1 .sec-2{
        margin-top:50px;
    }
    .how-fast-section .content-overflow-1 .sec-3{
        margin-top:50px;
    }
    .how-fast-section .blackoverlay {
        display: none;
    }
    .how-fast-section .content-overflow-1{
        position: unset;
    }
}

@media (min-width:769px) and (max-width:992px){
    .how-fast-section .content-overflow-1 .title-set {
        font-size: 25px;
    }
    .how-fast-section .content-overflow-1 .content {
        font-size: 12px;
    }   
    .how-fast-section .content-overflow-1 .sec-3 .rout-img {
        width: 60%;
        position: relative;
        left: 35%;
        top: 60px;
    }
    .how-fast-section .content-overflow-1 .sec-2 .icon-div .icon {
        width: 55px;
    }
    .how-fast-section .content-overflow-1 .sec-2 .get .get-p {
        font-size: 12px;
    }
    .how-fast-section .content-overflow-1 .sec-2 .get .spped {
        font-size: 41px;
    }
    .how-fast-section .content-overflow-1 .sec-2 .get .gbps {
        font-size: 12px;
        margin-top: 17px;
    }
    .how-fast-section .content-overflow-1 .ultra {
        font-size: 12px;
        margin: -12px 0px 0px 8px;
    }
    .services-section {
        padding: 15% 0% 0% 0%;
    }
    .how-fast-section .content-overflow-1 .sec {
        width: 100%;
    }
}

@media (min-width:993px) and (max-width:1200px){
    .how-fast-section .content-overflow-1 .title-set {
        font-size: 25px;
    }
    .how-fast-section .content-overflow-1 .content {
        font-size: 12px;
    }   
    .how-fast-section .content-overflow-1 .sec-3 .rout-img {
        width: 60%;
        position: relative;
        left: 35%;
        top: 60px;
    }
}

@media (min-width:1201px) and (max-width:1400px){
    .how-fast-section .content-overflow-1 .sec {
        width: 85%;
    }
    .how-fast-section .content-overflow-1 .sec-3 .rout-img {
        width: 70%;
        position: relative;
        left: 20%;
    }
    .how-fast-section .content-overflow-1 .title-set {
        font-size: 30px;
    }
    .how-fast-section .content-overflow-1 .content {
        font-size: 15px;
    }
}

@media (min-width:1401px) and (max-width:1700px){
    .how-fast-section .content-overflow-1 {
        top: 15%;
        width: 85%;
    }
}

@media (min-width:1701px) and (max-width:2000px){
    .how-fast-section .content-overflow-1 {
        top: 20%;
    }
}

@media (min-width:2001px) and (max-width:2200px){
    .how-fast-section .content-overflow-1 {
        top: 20%;
    }
}
@media (min-width:2201px) and (max-width:2600px){
    .how-fast-section .content-overflow-1 {
        top: 20%;
    }
}

/* end .how-fast-section style  */











/* start counter-section style  */
    .counter-section{
        position: relative;
        width: 100%;
    }
    .counter-section .conter-overlay{
        position: absolute;
        width: 100%;
        bottom: -65px;        
        z-index: 999;
    }
    .counter-section .conter-overlay .counter-box{       
        border-radius: 14px;
        background: #fff;
        box-shadow: 0px 1px 10px rgba(0, 84, 179, 0.4);
        padding: 0px 50px;        
       
    }
    .counter-section .conter-overlay .counter-box .box .number{
        margin-bottom: 0px;
        font-weight: 600;
        font-size: 60px;
        color: #0054b3;
    }
    .counter-section .conter-overlay .counter-box .box .memmm{
        font-weight: 600;
        font-size: 15px;
        color: #000;
        margin-bottom: 0px;
    }
    .counter-section .conter-overlay .counter-box .box{
        padding: 30px 0px;        
        margin: 0px 50px;
        border-bottom: 3px solid transparent;
    }
    .counter-section .conter-overlay .counter-box:hover .box{
       border-bottom: 3px solid #ffa027;
    }



    @media (min-width:0px) and (max-width:320px){

    }

    @media (min-width:321px) and (max-width:485px){
        
    }


    @media (min-width:0px) and (max-width:575px){
        .counter-section .conter-overlay .counter-box {
            width: 100%;
            padding: 10px;
        }
        .counter-section .conter-overlay{
            position: unset;           
        }
        .counter-section .conter-overlay .counter-box .box {
            padding: 15px 0px;
            margin: 0px 15px;
            height: 100%;
        }
        .counter-section .conter-overlay .counter-box .box .number {
            font-size: 30px;
        }
        .counter-section .conter-overlay .counter-box .box .memmm {
            font-size: 12px;
        }
        .counter-section {
            position: relative;
            width: 100%;
            margin: 8% 0% 0% 0%;
        }        
    }


    @media (min-width:576px) and (max-width:768px){
        .counter-section .conter-overlay .counter-box {
            width: 100%;
            padding: 0px 20px;
        }
        .counter-section {
            position: relative;
            width: 100%;
            margin: 8% 0% 0% 0%;
        }
        .counter-section .conter-overlay .counter-box .box {
            padding: 15px 0px;
            margin: 0px 15px;
        }
        .counter-section .conter-overlay .counter-box .box .number {
            font-size: 35px;
        }
        .counter-section .conter-overlay .counter-box .box .memmm {
            font-size: 12px;
        }
        .counter-section .conter-overlay{
            position: unset;           
        }

    }

    @media (min-width:769px) and (max-width:992px){
        .counter-section .conter-overlay .counter-box {          
            padding: 0px 25px;
        }
        .counter-section .conter-overlay .counter-box .box {
            padding: 15px 0px;
            margin: 0px 0px;
        }
        .counter-section .conter-overlay .counter-box .box .number {
            font-size: 35px;
        }
        .counter-section .conter-overlay .counter-box .box .memmm {
            font-size: 11px;
        }
    }

    @media (min-width:993px) and (max-width:1200px){
       
        .counter-section .conter-overlay .counter-box .box {
            margin: 0px 10px;
        }
        .counter-section .conter-overlay .counter-box .box .number {
            font-size: 40px;
        }
    }
    @media (min-width:1201px) and (max-width:1400px){
     
        .counter-section .conter-overlay .counter-box .box {
            margin: 0px 25px;
        }
        .counter-section .conter-overlay .counter-box .box .number {
            font-size: 50px;
        }
    }

    @media (min-width:1401px) and (max-width:1700px){
       
        .counter-section .conter-overlay .counter-box .box {
            margin: 0px 25px;
        }
    }

    @media (min-width:1701px) and (max-width:2000px){

    }

    @media (min-width:2001px) and (max-width:2200px){
        
    }
    @media (min-width:2201px) and (max-width:2400px){
    
    }
    @media (min-width:2401px) and (max-width:2600px){
     
    }


/* end counter-section style  */
