/* Style for the container */
.get_in_touch {
  position: relative;
  overflow: hidden;
  font-family: 'Poppins';
}

.hor-bd-div .bd1 {
  height: 5px;
  background-color: #fff;
  width: 50%;
}

.hor-bd-div .bd2 {
  height: 5px;
  background-color: #ff9c21;
  width: 50%;
}

.get_in_touch .overlay-content {
  /* background-image: url("/public/assets/images/GetInTouch/Baground-image.png"); */
  background-image: url("/public/assets/Images/GetInTouch/Baground-image.png");
  /* background-image: url("../../../../public/assets/Images/"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;


}

.get_in_touch .overlay-content .get_in_touch_content .overlay-text .icon-text {
  padding: 50px 0px 100px 0px;
}

.get_in_touch .overlay-content .get_in_touch_content .overlay-text .wifi-icon {
  display: flex;
}

.get_in_touch .overlay-content .get_in_touch_content .overlay-text .wifi-icon .get-bg-img {
  /* width: 21px; */
  height: 21px;
  margin: 10px;
}

.get_in_touch .overlay-content .get_in_touch_content .overlay-text .heading {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0;
}

.get_in_touch .overlay-content .get_in_touch_content .overlay-text .descriptn {
  font-size: 25px;

}






/* Style for the overlay form */
.get_in_touch .form-section .overlay-form {
  background-color: white;
  padding: 60px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  color: #707070;

}

.get_in_touch .form-section .form-control {
  border: 2px solid #707070;
}

/* Style for the submit button */



.overlay-content .content {
  position: absolute;
}


.get_in_touch .form-section {
  padding-bottom: 50px;
}



.get_in_touch .overlay-form {

  width: 100%;

  text-align: center;



}



.get_in_touch .form-section .overlay-form .custom-button .btn {



  padding: 10px 45px;
  cursor: pointer;
  background-color: #0054B3;
  color: white;
  border: 3px solid #bacadb;
  border-style: double;
}

.get_in_touch .form-section .overlay-form .custom-button .btn:hover {
  color: black;
  border: 2px solid #0054B3;
  background-color: white;

}


@media screen and (min-width: 0px) and (max-width: 320px) {
  .get_in_touch .overlay-content .get_in_touch_content .overlay-text .heading {
    font-size: 25px;
    padding: 0px;
  }
  .get_in_touch .overlay-content .get_in_touch_content .overlay-text .icon-text {
    padding: 15px 12px 0px 39px;
  }
  .get_in_touch .overlay-content .get_in_touch_content .overlay-text .descriptn {
    padding-left: 35px;
    font-size: 20px;
  }
  .get_in_touch .form-section {
    padding: 0px 0px 0px 0px;
  }
  .get_in_touch .overlay-content {
    padding: 5% 0%;
  }
}

@media screen and (min-width: 321px) and (max-width: 485px) {
  .get_in_touch .overlay-content .get_in_touch_content .overlay-text .heading {
    font-size: 25px;
    padding: 10px;
  }
  .get_in_touch .overlay-content .get_in_touch_content .overlay-text .descriptn {
    padding-left: 35px;
    font-size: 20px;
  }
  .get_in_touch .overlay-content .get_in_touch_content .overlay-text .icon-text {
    padding: 15px 12px 0px 39px;
  }
  .get_in_touch .get_in_touch_content .descriptn {
    padding-left: 0px;
  }
  .get_in_touch .form-section {
    padding: 0px 0px 0px 0px;
  }
  .get_in_touch .overlay-content {
    padding: 5% 0%;
  }
}

@media screen and (min-width: 486px) and (max-width: 768px) {
  .get_in_touch .overlay-content .get_in_touch_content .overlay-text .icon-text {

    padding: 15px 12px 0px 39px;
  }

  .get_in_touch .form-section {
    padding: 0px 0px 0px 0px;
  }

  .get_in_touch .overlay-content {
    padding: 5% 0%;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {}

@media screen and (min-width: 993px) and (max-width: 1200px) {}

@media screen and (min-width: 1200px) and (max-width: 1400px) {}