/* Why UCN Broadband ? css start */
.Why-UCN-Broadband {
    margin: 3% 0%;
  }
  
  .Why-UCN-Broadband .text-holder h1 {
    text-align: center;
    font-size: 23px;
    font-family: poppins;
    font-weight: bold;
    color: #0a0a0a;
  }
  
  .Why-UCN-Broadband .text-holder:hover .underline {
    width: 45px;
    left: 48%;
    transition: 0.5s ease;
  }
  
  .Why-UCN-Broadband .text-holder .underline {
    background-color: #216fc282;
    bottom: 0px;
    height: 2px;
    width: 183px;
    position: relative;
    display: block;
    left: 43%;
  }
  .Why-UCN-Broadband .card-section {
    margin-top: 5%;
  }
  
  .Why-UCN-Broadband .card-section .card .card-img {
    width: 67px;
    height: 66px;
    margin: 12% auto;
  }
  
  .Why-UCN-Broadband .card-section .card {
    height: 344px;
    margin: 0% 5%;
    border: none;
    box-shadow: 0px 5px 16px -5px grey;
    border-radius: 5px;
  }
  
  .Why-UCN-Broadband .card-section .card .card-body .card-title {
    font-size: 16px;
    font-weight: bold;
    font-family: poppins;
    color: #0a0a0a;
    padding: 0% 20%;
  }
  
  .Why-UCN-Broadband .card-section .card .card-body .card-text {
    font-size: 14px;
    font-weight: 500;
    font-family: poppins;
    color: #0a0a0a;
    margin-top: 10%;
    padding: 0% 8%;
  }
  
  .Why-UCN-Broadband .card-section .card .card-body {
    text-align: center;
    padding-top: 10%;
  }
  
  .Why-UCN-Broadband .card-section .card .underline {
    background-color: #ff9100;
    bottom: 0px;
    height: 6px;
    width: 97%;
    position: relative;
    display: block;
    left: 5px;
    border-radius: 5px 5px 0px 0px;
  }
  
  /* Why UCN Broadband ? css end */
  