/* main-footer css start */
.footer {
  background-image: url("/public/assets/Images/footer/footer_back.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer .footer-logo {
    width: 170px;
}

.footer .links {
    margin: 10px 0px;
}

.footer .links .text-holder h6 {
    font-family: poppins;
    font-size: 15px;
    color: #ffffff;
    margin-bottom: 20px;
}

.footer .links .text-holder p {
    margin-bottom: 5px;
    font-size: 12px;
    font-family: poppins;
    font-weight: medium;
    color: #ffffff;
}

.footer .links .text-holder .icon{
    color: #ffffff;
}

.footer .links .text-holder .text {
    margin-bottom: 10px;
    margin-right: 16%;
}

.footer .links .text-holder a {
  text-decoration: none;
}
.footer .links .text-holder .hr-lines-1:before {
    content: " ";
    display: block;
    height: 1px;
    width: 170px;
    position: absolute;
    top: -30px;
    left: 90px;
    background: #ffffff;
}
  .hr-lines-1{
    position: relative;
  }

  .footer .links .text-holder .hr-lines-2:before {
    content: " ";
    display: block;
    height: 1px;
    width: 160px;
    position: absolute;
    top: -30px;
    left: 90px;
    background: #ffffff;
}
  .hr-lines-2{
    position: relative;
  }

  .footer .links .text-holder .hr-lines-3:before {
    content: " ";
    display: block;
    height: 1px;
    width: 80px;
    position: absolute;
    top: -30px;
    left: 180px;
    background: #ffffff;
}
  .hr-lines-3{
    position: relative;
  }


  @media (min-width:1024px) and (max-width:1199px){
  .footer .links .text-holder .hr-lines-2:before{
    width: 100px;
}

.footer .links .text-holder .hr-lines-3:before {
    width: 65px;
}
  }

  @media (min-width:992px) and (max-width:1023px){
  .footer .links .text-holder .hr-lines-2:before {
    width: 100px;
}

.footer .links .text-holder .hr-lines-3:before {
    width: 50px;
}
  }

  @media (min-width:768px) and (max-width:991px){
  .footer .links .text-holder .hr-lines-2:before {
    width: 70px;
}

.footer .links .text-holder .hr-lines-3:before {
   display: none;
}
  }

  @media (min-width:0px) and (max-width:485px){
  .footer .footer-logo {
    width: 140px;
}
  }

/* main-footer css end */

/* bottom footer css start */
.bottom-footer {
    background-color: #00234b;
    padding: 0px 0px 5px 0px;
}
.bottom-footer .text-holder {
    border-bottom: 2px solid #009be9;
    padding-bottom: 3px;
}
.bottom-footer{
    border-bottom: 1px solid #009be9;
}

.bottom-footer .text-holder span {
  font-size: 10px;
  font-weight: 100;
  font-family: poppins;
  color: #ffffff;
}
/* bottom footer css end */
