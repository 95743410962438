
.main .registration-form 
{
    background-image: url("/public/assets/Images/Upgrade/banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 236px;
    position: relative;
    overflow: hidden;
    
}


.main .form-section .remarks {
    height: 127px;
}


