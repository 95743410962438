.services-section {
  position: relative;
  width: 100%;
  font-family: 'Poppins';
  padding: 5% 0% 0% 0%;
}
.services-section .bg-circle-div .bg-img{
    position: absolute;
    width:20%;
    top: 0px;
    right: 0px;
    opacity: 0.50;
    height: 70%;
}

.service-text .text-holder {
  display: flex;
}


.services-section .service-text .icon {
  height: 20px;
  width: 25px;
}
.services-section .service-text .heading {
  font-weight: bold;
  font-size: 30px;
}
.services-section .service-text .text-content {
  
  font-size: 20px;
}

.services-section .service-text .image-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
   z-index: 1;
}

.services-section .service-text .image-section .images {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.services-section .image-section .images .card {
  display: inline-block;
  cursor: pointer;
  margin: 10px;
  position: relative;
  padding: 15px;
  background-color: #ffffff;
  border: 2px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #437dbf;
  width: 100%;
  height: 80%;
  min-height:280px;
 
}

.services-section .image-section .images .card .card-body
{
    /* text-align: center; */
    padding: 0px;
    width: "50px"; 
    height:"285px";
}

.services-section .image-section .images .card .card-body .card-title
{
  text-align: center;
    font-size: 20px;
    font-weight: bold;
}
.services-section .image-section .images .card .card-body .card-text
{
    font-size: 14px;
    text-align: center;
}








.services-section .service-text .image-section .images img {
  max-width: 100%;
  height: auto;
  width: 100%;
  position: relative;
  bottom: 0px;
  transition: 0.5s;
  padding: 0px 20px;
}

.services-section .service-text .image-section .images:hover img {
  transform: translateY(-30px);   
  transform: scale(1.1);
  position: relative;
  bottom: 25px;
}




.services-section .image-section .images .card .card-body .go_btn{
    position: absolute;
    z-index: -999;
    width: 95%;
    left: 2%;
    bottom: 0%;
    border-radius: 8px;
    font-size: 12px;
    background-color: #0054B3;
   padding: 10px;
}

.services-section .image-section .images .card{
    position: relative;
}
.services-section .image-section .images .card:hover .go_btn{
 bottom: -13%;
 transition: 0.1s all ease-in;
 margin: 2px;
}




@media screen and (min-width:0px) and (max-width: 320px) {
  
  .services-section .service-text .heading {
    
    font-size: 25px;
  }
  .services-section .service-text .text-content {
  
    font-size: 15px;
  }


  .services-section .bg-circle-div .bg-img{
    
    width:50%;
    
    height: 10%;
}
}
@media screen and (min-width: 321px) and (max-width: 485px) {

  .services-section .service-text .text-content {
  
    font-size: 15px;
  }
  .services-section .bg-circle-div .bg-img{
    
    width:50%;
    
    height: 10%;
}

.services-section .image-section .images .card {
  width: 90%;
  height: 90%;
  margin: 10px;
  
}

}
@media screen and (min-width: 486px) and (max-width: 767px) {

  .services-section .bg-circle-div .bg-img{
   
    width:50%;
    
    height: 10%;
    
}
.services-section .image-section .images .card {
  width: 90%;
  height: 90%;
  margin: 10px;
  
}


}
@media screen and (min-width: 768px) and (max-width: 991px) {

  .services-section .bg-circle-div .bg-img{
    
    width:50%;
    
    height: 30%;
}
.services-section .image-section .images .card {
 
  width: 90%;
  height: 90%;
  margin: 10px;
  
}
  
}
@media screen and (min-width: 992px) and (max-width: 1200px) {


  .services-section .bg-circle-div .bg-img{
    
    width:25%;
    
    height: 70%;
  }
  .services-section .image-section .images .card {  
    height: 80%; 
  }
  .services-section {
    padding: 10% 0% 0% 0%;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .services-section .image-section .images .card {    
    height: 100%;    
  }
  .services-section {
    padding: 10% 0% 0% 0%;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1800px) {
  .services-section .image-section .images .card {    
    height: 100%;   
  }
  .services-section {
    padding: 10% 0% 2% 0%;
  } 
  
}