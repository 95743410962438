.internet-speed {
    background: #F5FAFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 0px 0px 5px 5px;
}

.internet-speed .card {
    border-top: 4px solid #FF9100;
    background-color: #FFF;
    box-shadow: 0px 3px 3px #00000014;
    border-radius: 0px 0px 5px 5px;
    font-size: 16px;
    color: #000;
    font-weight: 600;
}

.internet-speed .card p {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    padding: 10px 20px 10px 40px;
}

.internet-speed .card p span {
    font-size: 16px;
    color: #000;
    font-weight: 600;
}

.internet-speed .underline-class {
    border-bottom: 1px solid #00000029;
    padding: 0 20px;
}

.select-button {
    width: fit-content;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    background-color: #0054B3;
    color: #FFF;
    padding: 5px 20px 5px 20px ;
}

.select-btn-main1 {
    position: absolute;
    top: 101%;
    right: 58%;
}

.select-btn-main2 {
    position: absolute;
    top: 101%;
    right: 38%;
}

.select-btn-main3 {
    position: absolute;
    top: 101%;
    right: 16%;
}

.internet-speed .text-holder-speed p {
    font-size: 16px;
    color: #000;
    font-weight: 600;
}


.Plan-Type .second-tab .nav-pills .nav-link {
    width: 222px;
    height: 44px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 0px 5px 5px 0px;
    margin-bottom: 18%;
    box-shadow: 0px 0px 6px #00000029;
    color: #000;
    font-size: 16px;
    color: #0A0A0A;
    font-weight: 600;
}

.Plan-Type .second-tab .nav-pills .nav-link.active{
    width: 222px;
    height: 44px;
    background: #0054B3;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 0px 5px 5px 0px;
    margin-bottom: 18%;
    box-shadow: 0px 0px 6px #00000029;
    font-size: 16px;
    color: #FFF;
    font-weight: 600;
}

.Plan-Type .nav-pills .nav-link {
    width: 222px;
    height: 44px;
    background: none;
    margin-bottom: 18%;
    color: #000;
    font-size: 16px;
    color: #0A0A0A;
    font-weight: 600;
}

.Plan-Type .nav-pills .nav-link.active{
    width: 222px;
    height: 44px;
    color: #0054B3;
    border-radius: 0px 5px 5px 0px;
    margin-bottom: 18%;
    font-size: 16px;
    font-weight: 600;
}


.Plan-Type .arrow-img {
    width: 17px;
    height: 10px;
}

.modal .modal-title {
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid;
}


@media(min-width:0px) and (max-width:320px) {
    .select-btn-main1 {
        position: none;
        top: 0px;
        right: 0px;
    }
    
    .select-btn-main1 {
        position: none;
        top: 0px;
        right: 0px;
    }
    
    .select-btn-main1 {
        position: none;
        top: 0px;
        right: 0px;
    }
}

@media(min-width:321px) and (max-width:485px) {
    .select-btn-main1 {
        position: none;
        top: 0px;
        right: 0px;
    }
    
    .select-btn-main1 {
        position: none;
        top: 0px;
        right: 0px;
    }
    
    .select-btn-main1 {
        position: none;
        top: 0px;
        right: 0px;
    }
}

@media(min-width:486px) and (max-width:576px) {
    .select-btn-main1 {
        position: none;
        top: 0px;
        right: 0px;
    }
    
    .select-btn-main1 {
        position: none;
        top: 0px;
        right: 0px;
    }
    
    .select-btn-main1 {
        position: none;
        top: 0px;
        right: 0px;
    }
}

@media(min-width:577px) and (max-width:768px) {
  
}

@media(min-width:769px) and (max-width:992px) {
   
}

@media(min-width:992px) and (max-width:1200px) {
   
}
