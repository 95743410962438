/* terms-and-conditions css start */
.terms-and-conditions {
  margin-top: 3%;
}

.terms-and-conditions .text-holder h1 {
  font-size: 18px;
  font-family: poppins;
  font-weight: bold;
  padding-left: 12px;
  color: #0a0a0a;
}

.terms-and-conditions .text-holder span {
  font-size: 14px;
  font-weight: normal;
  font-family: poppins;
  color: #0a0a0a;
}

.terms-and-conditions .text-holder ul {
  color: #878788;
}

/* terms-and-conditions css end */


@media(min-width:0px) and (max-width:320px) {
  .terms-and-conditions .text-holder span {
    font-size: 13px;
  }

  .terms-and-conditions .text-holder h1 {
    text-align: center;
  }

  .Why-UCN-Broadband .text-holder h1 {
    font-size: 20px;
  }
}

@media(min-width:321px) and (max-width:485px) {
  .terms-and-conditions .text-holder span {
    font-size: 13px;
  }

  .terms-and-conditions .text-holder h1 {
    text-align: center;
  }

  .Why-UCN-Broadband .text-holder h1 {
    font-size: 20px;
  }
}

@media(min-width:486px) and (max-width:576px) {
  .terms-and-conditions .text-holder span {
    font-size: 13px;
  }

  .terms-and-conditions .text-holder h1 {
    text-align: center;
  }

  .Why-UCN-Broadband .text-holder h1 {
    font-size: 20px;
  }
}
