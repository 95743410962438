/* Home css started */

.home-banner-section {
    position: relative;
    width: 100%;
}

.home-banner-section .black-overlay-vdo {
    /* position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: #000;
        z-index: 999; */
}

.home-banner-section .swiper-button-prev:after,
.swiper-button-next:after {
    display: none !important;
}

.home-banner-section .swiper-button-next,
.swiper-rtl .swiper-button-prev {
    display: none;
}

.home-banner-section video.video-class-grid {
    width: 100% !important;
}

.home-banner-section .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 17%;
    width: 100%;
}

.home-banner-section span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 20px;
    height: 20px;
    background-color: white;
}

.home-banner-section span.swiper-pagination-bullet.swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    background-color: white;
}

.home-banner-section v.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 14% !important;
    left: 0;
    width: 100%;
}

.home-banner-section .blue-background {
    left: 16%;
    top: 0%;
    width: 574px;
    height: 99%;
    background: #001e7596;
    position: absolute;
}

.home-banner-section .blue-background .content-main h1.content {
    color: #ffff;
    font-size: 26px;
}

.home-banner-section .blue-background .content-main img.img-fluid {
    width: 85%;
}

.home-banner-section .blue-background .content-main {
    padding: 87px 0px 0px 58px;
}

.home-banner-section .blue-background .check-btn .btn {
    border: 2px solid #bbbbbb;
    background-color: #0054b3;
    position: absolute;
    bottom: 20%;
}

.home-banner-section .blue-background .check-btn .btn:hover {
    border: 2px solid #0054b3;
    background-color: white;
    color: black;
}

/* Home css end */

/* cards section css */
.home-banner-section .overlay-cards .cards-main-section {
    position: absolute;
    left: 0%;
    width: 100%;
    top: 90%;
    z-index: 999;
}

.cards-main-section .card-image {
    height: 360px;
    width: 95%;
    object-fit: cover;
    border-radius: 10px;
}

.cards-main-section {
    /* position: absolute;
    top: 66%;
    z-index: 1; */
}

.cards-main-section .images-main-section {
    position: relative;
}

.cards-main-section .images-main-section .text {
    padding: 107px 0px 0px 43px;
    position: absolute;
    top: 28%;
}

.cards-main-section .images-main-section .text h6.home {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #ffffff;
}

.cards-main-section .images-main-section .text h6.speed {
    font-size: 13px;
    letter-spacing: 0px;
    color: #ffffff;
}

.cards-main-section .images-main-section .text h6.support {
    font-size: 13px;
    letter-spacing: 0px;
    color: #ffffff;
}

/* cards section css */




@media (min-width:0px) and (max-width:320px) {
    .home-banner-section .blue-background .content-main {
        padding: 15px 0px 0px 15px;    
    }
    .home-banner-section .blue-background .content-main h1.content {
        font-size: 14px;
    }
    .home-banner-section .blue-background .content-main img.img-fluid {
        width: 30%;
    }
    .home-banner-section .blue-background {
        left: 7%;
        width: 100%;
        height: 96%;
    }
    .home-banner-section .blue-background .check-btn .btn {
        font-size: 13px;
        left: 50%;
    }
    .home-banner-section .overlay-cards .cards-main-section {
        position: unset;
        padding: 20px;
        width: 100%;
    }
    .cards-main-section .card-image {
        height: 100%;
        width: 100%;
    }
    .cards-main-section .images-main-section .text {
        padding: 0px 0px 0px 0px;
        top: 35%;
        left: 10%;
    }
}

@media (min-width:321px) and (max-width:485px) {
    .home-banner-section .blue-background .content-main {
        padding: 15px 0px 0px 15px;    
    }
    .home-banner-section .blue-background .content-main h1.content {
        font-size: 14px;
    }
    .home-banner-section .blue-background .content-main img.img-fluid {
        width: 30%;
    }
    .home-banner-section .blue-background {
        left: 7%;
        width: 100%;
        height: 96%;
    }
    .home-banner-section .blue-background .check-btn .btn {
        font-size: 13px;
        left: 50%;
    }
    .home-banner-section .overlay-cards .cards-main-section {
        position: unset;
        padding: 30px;
        width: 100%;
    }
    .cards-main-section .card-image {
        height: 100%;
        width: 100%;
    }
    .cards-main-section .images-main-section .text {
        padding: 0px 0px 0px 0px;
        top: 40%;
        left: 10%;
    }
}

@media (min-width:486px) and (max-width:575px) {
    .home-banner-section .blue-background .content-main {
        padding: 15px 0px 0px 15px;    
    }
    .home-banner-section .blue-background .content-main h1.content {
        font-size: 14px;
}
.home-banner-section .blue-background .content-main img.img-fluid {
    width: 30%;
}
.home-banner-section .blue-background {
    left: 7%;
    width: 100%;
    height: 96%;
}
.home-banner-section .blue-background .check-btn .btn {
    font-size: 13px;
}
.home-banner-section .overlay-cards .cards-main-section {
    position: unset;
    padding: 30px;
    width: 100%;
}
.cards-main-section .card-image {
    height: 100%;
    width: 100%;
}
}

@media (min-width:576px) and (max-width:768px) {
    .home-banner-section .blue-background .content-main {
        padding: 15px 0px 0px 15px;    
    }
    .home-banner-section .blue-background .content-main h1.content {
        font-size: 14px;
}
.home-banner-section .blue-background .content-main img.img-fluid {
    width: 30%;
}
.home-banner-section .blue-background {
    left: 7%;
    width: 100%;
    height: 96%;
}
.home-banner-section .blue-background .check-btn .btn {
    font-size: 14px;
}
.cards-main-section .card-image {
    height: 200px;
    width: 100%;
}
.cards-main-section .images-main-section .text {
    padding: 25px 0px 0px 10px;
    top: 40px;
    }
    .cards-main-section .images-main-section .text h6.home {
        font-size: 15px;
        line-height: 15px;
}
}

@media (min-width:769px) and (max-width:992px) {
    .cards-main-section .card-image {
        height: 250px;
    }
    .cards-main-section .images-main-section .text {
        padding: 20px 0px 0px 10px;
        top: 30%;
    }
    .cards-main-section .images-main-section .text h6.home {
        font-size: 20px;
        line-height: 22px;
    }
    .home-banner-section .blue-background .content-main {
        padding: 20px 0px 0px 15px;
    }
    .home-banner-section .blue-background .content-main h1.content {
        font-size: 20px;
    }
    .home-banner-section .blue-background .content-main img.img-fluid {
        width: 50%;
    }
    .home-banner-section .blue-background .check-btn .btn {
        font-size: 14px;
    }
}

@media (min-width:993px) and (max-width:1200px) {
    .home-banner-section .blue-background {
        left: 10%;
        width: 500px;
    }
    .home-banner-section .blue-background .content-main h1.content {
        font-size: 20px;
}
.home-banner-section .blue-background .content-main img.img-fluid {
    width: 60%;
}
.home-banner-section .blue-background .check-btn .btn {
    font-size: 15px;
}
}

@media (min-width:12001px) and (max-width:1400px) {
    .home-banner-section .blue-background {
        left: 10%;
        width: 500px;
    }
}

@media (min-width:1880px) and (max-width:2690px) {}