/* top header css start */

.top-header {
    background-color: #3A3A3A;
    padding: 5px 0px;
}

.top-header .text-holder .header-link span {
    color: #ffffff;
    font-size: 14px;
    font-weight: 100;
    font-family: poppins;
}

.top-header .text-holder .lCO-link span {
    color: #ffffff;
    font-size: 14px;
    font-weight: 100;
    font-family: poppins;
}

.top-header .text-holder .recharge-link span {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
    font-size: 14px;
    font-weight: 100;
    font-family: poppins;
}

.top-header .text-holder .contact-no span {
    color: #ffffff;
    font-size: 11px;
    font-weight: 100;
    font-family: poppins;
}

.top-header .text-holder .contact-no  svg {
    color: #ffffff;
    font-size: 12px;
    font-weight: 100;
    font-family: poppins;
}

.top-header .text-holder .email-info span {
    color: #ffffff;
    text-decoration: none;
    font-size: 12px;
    font-weight: 100;
    font-family: poppins;
}
.top-header .text-holder a {
    text-decoration: none;
}

.top-header .text-holder .email-info svg {
    color: #ffffff;
    font-size: 12px;
    font-weight: 100;
    font-family: poppins;
}


.top-header .text-holder .contact-no{
   text-align: end;
}

.top-header .text-holder .recharge-link {
    text-align: initial;
}

.top-header .text-holder .header-link {
    text-align: start;
}

@media (min-width:0px) and (max-width:767px){
.top-header .text-holder .contact-no {
    text-align: inherit;
}
.top-header .text-holder .recharge-link {
    text-align: end;
}
.top-header .text-holder .header-link {
    text-align: end;
}
}


/* top header css start */

/* main header css start */

.navbar {
    padding-top: 20px;
    background-color: #ffffff;
}

.navbar .nav-link {
    font-size: 16px;
    font-family: poppins;
    color: #0A0A0A;
}


.navbar .nav-link.active {
    color: #0054B3;
    font-weight: bold;
    border-bottom: 2px solid #FF9100;
    width: fit-content;
}

.navbar .dropdown-toggle::after {
    color: #FF9100;
}

.navbar  .navbar-nav .dropdown-menu{
    padding: 0px;
}

.navbar  .navbar-nav .dropdown-menu .dropdown-item:hover {
    background-color: #FF9100;
    transition: 0.4s ;
    transition-delay:0.2s;
    color: #000000;
    }
    
.navbar  .navbar-nav .dropdown-menu .dropdown-item.active {
background-color: #FF9100;
color: #000000;
}

.navbar .header-logo {
    width: 200px;
}

.navbar .nav-item {
    margin: 0px 18px;
}

.navbar .navbar-toggler {
    color: #ffffff;
    background-color: transparent;
    border: none;
    transition: none;
}

@media (min-width:0px) and (max-width:1200px){
.navbar .nav-item {
    margin: 0px 5px;
}
}

@media (min-width:0px) and (max-width:485px){
    .navbar .header-logo {
        width: 140px;
    }
}

/* main header css end */

