/* Router css started */
.rizwan-hd-section{
    padding: 20px 0px;
}
.rizwan-hd-section .router-banner-div .banner{
    background-position: center;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    
    width: 100%;
    }
  
    .digital .who-heading h1.rizwan {
    font-size: 30px;
    color: #0A0A0A;
    font-weight: bold;
    }
    /* Router css end */
    
    
    .router-cards{
     height: 190px;
     width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px -3px 6px #00000029, 0px 1px 5px #0054B33B;
    border-radius: 10px;     
    }
    .router-slider .router-cards img.hd {
    width: 18%;
    height: auto;
    }
    .router-slider .router-cards img.time {
    width: 31%;
    }
    .router-slider .router-cards .main-images {
        text-align: -webkit-center;
        /* height: 120px ; */
    }
    .router-slider img.digital {
    width: 47%;
    }
    .router-slider .router-cards .recording {
    color: #131313; 
    font-size: 23px;
    font-weight: bold;   
    }
    .router-slider .router-cards p.quality {
    color: #131313;
    font-size: 14px;
    }

    .router-slider-section .router-slider .swiper{
        padding: 30px 0px;
    }
    .router-slider-section .router-slider  .swiper-pagination{
        top: 93%;
    }
    
    .router-slider .swiper-button-prev:after, .swiper-button-next:after {
    display: none;
    }
    
    @media (min-width:0px) and (max-width:575px){
        .router-slider .router-cards .recording {
            font-size: 16px;
        }
        .router-slider .router-cards p.quality {
            font-size: 13px;
        }
        .digital .who-heading h1.rizwan {
            font-size: 25px;
        }
    }


    @media (min-width:2001px) and (max-width:2600px){
        .router-cards{
            height: 250px;
           }
    }

    /* media query started */