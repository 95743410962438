/* plans and package banner css start */
.main .Banner {
  background-image: url("/public/assets/Images/Broadband/PlansPackage/PlansPackage_banner_img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 236px;
  position: relative;
}

.main .Banner .banner-text {
  padding-top: 3%;
}

.main .Banner .banner-text .text-holder h1 {
  font-family: poppins;
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 3%;
  margin-top: 8%;
}

.main .Banner .banner-text .city-form .form-select {
  width: 50%;
  border-radius: 0px;
  color: #0a0a0a8c;
  font-size: 14px;
  font-weight: normal;
  font-family: poppins;
}

.main .Banner .banner-text .city-form label {
  font-size: 14px;
  font-weight: normal;
  font-family: poppins;
  color: #ffffff;
  margin-bottom: 2%;
}

/* plans and package banner css end */


@media(min-width:0px) and (max-width:320px) {
  .main .Banner .banner-text .text-holder h1 {
    font-size: 20px;
    margin-top: 16%;
  }

  .main .Banner .banner-text .city-form .form-select {
    width: 66%;
    color: #0a0a0a8c;
    font-size: 12px;
  }
}

@media(min-width:321px) and (max-width:485px) {}

@media(min-width:486px) and (max-width:576px) {}

@media(min-width:577px) and (max-width:768px) {}

@media(min-width:769px) and (max-width:992px) {}

@media(min-width:992px) and (max-width:1200px) {}