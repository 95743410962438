   /* who we are css started */
   .who_we_are {
    margin-top: -5%;
    height: auto;
    background-image: url("/public/assets/Images/banner/cardsbackground.png" );
    }
    .who-main {
    margin-top: 45%;
    }
    .who_we_are h4.who_we {
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 0px;
    color: #0A0A0A;
    }
    .who_we_are .experience{
    margin-left: 10%;
    font-weight: bold;
    font-size: 50px;
    letter-spacing: 0px;
    color: #0A0A0A;
    } 
    .who_we_are  p.lorem {
    font-weight: 400;
    margin-left: 11%;
    font-size: 19px;
    color: #0A0A0A;
    }
    .who_we_are video.who_are {
    width: 100%;
    margin-left: 12%;
    }
    .who_we_are  video.who_video {
    margin-left: 12%;
    width: 100%;
    }


    @media (min-width:0px) and (max-width:575px){
      .who_we_are  video.who_video {
         margin-left: 0%;
      }
      .who_we_are {
         margin-top: 0%;
      }
      .who-main {
         margin: 15% 0%;
         text-align: center;
      }
      .who_we_are h4.who_we {
         font-size: 20px;
      }
      .who_we_are .experience {
         margin-left: 0%;
         font-size: 25px;
      }
      .who_we_are .who-main .wifi img {
         width: 30px;
      }
      .who_we_are  p.lorem {
         margin-left: 0%;
         font-size: 15px;
      }

      .who_we_are .who-main .d-flex{
         justify-content: center;
      }
    }


    @media (min-width:576px) and (max-width:768px){
      .who_we_are  video.who_video {
         margin-left: 0%;
      }
      .who-main {
         margin-top: 52%;
      }
      .who_we_are h4.who_we {
         font-size: 25px;
      }
      .who_we_are .experience {
         margin-left: 10%;
         font-size: 30px;
      }
      .who_we_are .who-main .wifi img{
         width: 35px;
      }
      .who_we_are  p.lorem {
         font-size: 15px;
      }
      .home-banner-section .overlay-cards .cards-main-section {
         left: 0%;
         width: 100%;
      }
    }

    @media (min-width:769px) and (max-width:992px){
      .who_we_are  video.who_video {
         margin-left: 0%;
      }
      .who-main {
         margin-top: 52%;
      }
      .who_we_are h4.who_we {
         font-size: 25px;
      }
      .who_we_are .experience {
         margin-left: 10%;
         font-size: 30px;
      }
      .who_we_are .who-main .wifi img{
         width: 35px;
      }
      .who_we_are  p.lorem {
         font-size: 15px;
      }
    }


    @media (min-width:993px) and (max-width:1200px){
      .who_we_are  video.who_video {
         margin-left: 0%;
      }
      .who-main {
         margin-top: 52%;
      }
      .who_we_are h4.who_we {
         font-size: 25px;
      }
      .who_we_are .experience {
         margin-left: 10%;
         font-size: 30px;
      }
      .who_we_are .who-main .wifi img{
         width: 35px;
      }
      .who_we_are  p.lorem {
         font-size: 15px;
      }
    }


    @media (min-width:1201px) and (max-width:1400px){

    }

     /* who we are css end */


    